import { CardMedia } from '@mui/material';

const LogoName = 'inductvc-logo.png';

const logoWidth = '140px';

export const Logo1 = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, mt: 2, ml: 3, pb: 1 }}
    />
  );
};

export const LogoEditApplication = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, m: 2, mb: 1 }}
    />
  );
};

export const LogoSideBar = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, padding: '5px' }}
    />
  );
};
