import { Grid, Typography, Stack, Switch } from '@mui/material';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { getDistributorById, updateDistributorRM } from '../../redux-store/actions/userManagement';
import {
  AdddistributorRM,
  DistributorNewList,
  SubDistributorListType,
} from '../../redux-store/types/api-types';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from '../DataTable';
import { Location } from 'history';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { styles } from '../../Styles/styles';
import { UserListHeader } from './userBackButton';
import { SearchUser } from './SearchUser';
import { subdistributorTypes } from '../../utils/constant';

function DistributorRm({
  location,
}: {
  location: Location<{ distributorId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { distributorId } = location.state || {};
  const [distributorData, setDistributorData] = useState<DistributorNewList>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    distributorRmRowData: AdddistributorRM;
  } | null>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        if (distributorId) {
          const res = (await dispatch(
            getDistributorById(distributorId)
          )) as unknown as DistributorNewList;
          setDistributorData(res);
        } else {
          history.replace('/user-management');
        }
        if (!isComponentActive) return;
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) {
          setIsLoading(false);
        }
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [distributorId]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const headers = [
    {
      header: `Distributor's RM/CSE name`,
      renderCell: (row: SubDistributorListType) => {
        return (
          <Typography
            variant="h5"
            sx={styles.UsersClickbleColumn}
            component={RouterLink}
            to={{ pathname: 'distributors-rm-details', state: { subDistributorId: row.id } }}>
            {row.user?.firstName || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: `Distributor's RM code`,
      valueGetter: (row: SubDistributorListType) => row.distributors_RM_Code || 'N/A',
    },
    {
      header: `Distributor RM Type`,
      valueGetter: (row: SubDistributorListType) =>
        subdistributorTypes[row.subdistributorType] || 'N/A',
    },
    {
      header: 'Email Id',
      valueGetter: (row: SubDistributorListType) => row.user?.email || 'N/A',
    },
    {
      header: 'Mobile Number',
      valueGetter: (row: SubDistributorListType) => row.user?.phone || 'N/A',
    },
    {
      header: `AMC's RM`,
      valueGetter: (row: SubDistributorListType) => row.rm?.name || 'N/A',
    },
    {
      header: AccessHeader,
      renderCell: (row: AdddistributorRM): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ distributorRmRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: SubDistributorListType): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{
    //                 pathname: 'edit-distributors-rm',
    //                 state: { subDistributorId: row.id },
    //               }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-distributors-rm', state: { subDistributorId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Grid container>
        <Grid item sx={{ bgcolor: 'common.white', px: 4, width: '100%' }}>
          <SearchUser
            search={search}
            setSearch={setSearch}
            placeholder={`Search Distributor's RM`}
          />
          <UserListHeader
            addUser="Add Distributor's RM"
            listName="List of Distributor's RM(s)"
            accessForEditAdd={true}
            onClickAdd={() => history.push('add-distributors-rm', { distributorId })}
          />
          <Grid item xs={12} sm={12} lg={12}>
            {useMemo(
              () => (
                <DataTable
                  tableData={
                    distributorData?.subdistributors.filter(
                      (item) =>
                        item.user?.firstName?.toLowerCase()?.includes(search.toLowerCase()) ||
                        item.user?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
                        item.user?.phone?.toLowerCase()?.includes(search.toLowerCase())
                    ) || []
                  }
                  tableHeader={headers}
                  isLoading={isLoading}
                  currentPage={currentPage}
                  noOfPages={1}
                  paginationAction={handlePageChange}
                  renderAdditionalRow={true}
                  tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
                  tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
                />
              ),
              [isLoading, distributorData, search]
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.distributorRmRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                updateDistributorRM(openDialog.distributorRmRowData.id as number, {
                  ...openDialog.distributorRmRowData,
                  isActive: !openDialog?.distributorRmRowData.isActive,
                })
              )) as unknown as SubDistributorListType;
              setDistributorData({
                ...distributorData,
                subdistributors: distributorData?.subdistributors?.map((subdistributor) => {
                  if (Number(subdistributor.id) === Number(updatedResponse.id)) {
                    return {
                      ...subdistributor,
                      ...updatedResponse,
                    };
                  }
                  return subdistributor;
                }) as SubDistributorListType[],
              } as DistributorNewList);
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default DistributorRm;
