import { Box, Grid, LinearProgress } from '@mui/material';

import { useHistory } from 'react-router';
import { Formik } from 'formik';
import {
  getDistributorRMbyId,
  getRMsList,
  getDistributorById,
} from '../../redux-store/actions/userManagement';
import MFSelectField from '../../lib/formik/SelectField';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import {
  SubDistributorListType,
  AdddistributorRM,
  RmsType,
  newRmType,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import { addDistributorRM, updateDistributorRM } from '../../redux-store/actions/userManagement';
import { DistributorRmSchema } from '../../utils/schema';
import { Location } from 'history';
import { useEffect, useState } from 'react';
// eslint-disable-next-line
import { CountryCodesDropDown } from '../commonComponents';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { BackButtonAndHeader } from './userBackButton';
import { styles } from '../../Styles/styles';
import { AddressFieldEditable } from './AddressFields';
import { nonDigitRegex } from '../../utils/regex';
import { subdistributorTypes } from '../../utils/constant';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';

export default function AddDistributorRm({
  location,
}: {
  location: Location<{ distributorId: number; subDistributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { distributorId: ID, subDistributorId } = location.state || {};
  const [selectedRM, setSelectRm] = useState<RmsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [direct, setDirect] = useState<SubDistributorListType[]>([]);
  const [support, setSupport] = useState<SubDistributorListType[]>([]);

  const initialValues: AdddistributorRM = {
    name: '',
    email: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    phone: '',
    panNumber: '',
    distributors_RM_Code: '',
    subdistributorType: '',
    supportSubdistributorId: null,
    directSubdistributorIds: null,
    rmId: null,
    distributorId: ID,
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    isActive: true,
  };
  const [distributorRmDetails, setDistributorRmDetails] = useState(initialValues);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getRMsList({ isActive: true }))) as unknown as newRmType;
        const distributorRes = (await dispatch(
          getDistributorById(ID)
        )) as unknown as DistributorListType;
        setDirect(
          distributorRes.subdistributors.filter(
            (ele) => subdistributorTypes[ele.subdistributorType] === subdistributorTypes.direct
          )
        );
        setSupport(
          distributorRes.subdistributors.filter(
            (ele) => subdistributorTypes[ele.subdistributorType] === subdistributorTypes.support
          )
        );
        if (subDistributorId) {
          const getDistributorRmDetails = (await dispatch(
            getDistributorRMbyId(subDistributorId)
          )) as unknown as SubDistributorListType;
          const {
            user,
            panNumber,
            distributors_RM_Code,
            rmId,
            id,
            distributorId,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            isActive,
            subdistributorType,
            directSubdistributorIds,
            supportSubdistributorId,
          } = getDistributorRmDetails;
          const { firstName, countryCode, countryNameAndCode, phone, email } = user || {};
          setDistributorRmDetails({
            ...distributorRmDetails,
            name: firstName,
            panNumber,
            distributors_RM_Code,
            subdistributorType,
            rmId,
            id,
            distributorId,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            phone,
            email,
            isActive,
            supportSubdistributorId,
            directSubdistributorIds: directSubdistributorIds,
          });
        }
        if (!isComponentActive) {
          return;
        }
        const { rms } = res || {};
        setSelectRm(rms);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const onSubmit = async (values: AdddistributorRM) => {
    try {
      if (subDistributorId) {
        await dispatch(
          updateDistributorRM(subDistributorId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            supportSubdistributorId:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.direct
                ? values.supportSubdistributorId
                : null,
            directSubdistributorIds:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.support
                ? values.directSubdistributorIds
                : null,
          })
        );
        history.push('distributors-rm-details', {
          distributorId: values.distributorId,
          subDistributorId,
        });
      } else {
        await dispatch(
          addDistributorRM({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            distributorId: ID,
            supportSubdistributorId:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.direct
                ? values.supportSubdistributorId
                : null,
            directSubdistributorIds:
              subdistributorTypes[values.subdistributorType] === subdistributorTypes.support
                ? values.directSubdistributorIds
                : null,
          })
        );
        history.push('distributors-rm', { distributorId: values.distributorId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={distributorRmDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={DistributorRmSchema}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={subDistributorId ? `Distributor's RM` : `Onboarding Distributor's RM`}
              onClickBack={() =>
                subDistributorId
                  ? history.push('distributors-rm-details', {
                      distributorId: values.distributorId,
                      subDistributorId,
                    })
                  : history.push('distributors-rm', {
                      distributorId: values.distributorId,
                    })
              }
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Distributor's RM/CSE name *"
                      placeholder="Enter Distributor's RM/CSE name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="PAN"
                      placeholder="Enter PAN"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="distributors_RM_Code"
                      label="Distributor's RM Code"
                      placeholder="Enter Distributor's RM Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="subdistributorType"
                      label="Distributor RM Type *"
                      items={Object.keys(subdistributorTypes).map((role) => ({
                        key: subdistributorTypes[role],
                        value: role,
                      }))}
                    />
                  </Grid>
                  {subdistributorTypes[values.subdistributorType] ===
                    subdistributorTypes.direct && (
                    <Grid item xs={12} sm={6} md={4}>
                      <SearchableSelect
                        name="supportSubdistributorId"
                        label="CSE Name"
                        items={support
                          .sort((support1, support2) =>
                            (support1.user['firstName'] || '')
                              .toString()
                              .localeCompare((support2.user['firstName'] || '').toString())
                          )
                          .map((item) => ({
                            key: item.user.firstName,
                            value: item.id,
                          }))}
                        searchFieldPlaceholder={'Search CSE Name'}
                      />
                    </Grid>
                  )}
                  {subdistributorTypes[values.subdistributorType] ===
                    subdistributorTypes.support && (
                    <Grid item xs={12} sm={6} md={4}>
                      <MultipleSelect
                        name="directSubdistributorIds"
                        label="Distributor RM Name(s)  *"
                        items={direct.map((item) => ({
                          key: String(item.id),
                          value: item.user.firstName,
                        }))}
                        renderText="Distributor RM"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <SearchableSelect
                      name="rmId"
                      label="AMC's RM Name *"
                      items={selectedRM
                        .sort((rm1, rm2) =>
                          (rm1['name'] || '')
                            .toString()
                            .localeCompare((rm2['name'] || '').toString())
                        )
                        .map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      searchFieldPlaceholder={'Search RM'}
                      // onChange={({ target: { value } }) => {
                      //   setValues({
                      //     ...values,
                      //     rmId: value,
                      //   });
                      // }}
                    />
                  </Grid>
                  <AddressFieldEditable />
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
