import { Box, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';

export const SearchUser = ({
  search,
  setSearch,
  placeholder,
}: {
  search: string;
  setSearch: (search: string) => void;
  placeholder: string;
}): JSX.Element => {
  return (
    <Box paddingTop={4} width={{ xs: '100%', sm: '30%' }}>
      <FormControl variant="standard" sx={{ display: 'block' }}>
        <InputLabel
          htmlFor="input-with-icon-adornment"
          sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
          {placeholder}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          value={search}
          onChange={({ target: { value } }) => {
            setSearch(value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton>
                <SearchSharpIcon sx={{ color: 'primary.main' }} />
              </IconButton>
            </InputAdornment>
          }
          sx={{ width: '100%' }}
        />
      </FormControl>
    </Box>
  );
};
