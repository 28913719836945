import {
  Box,
  Button,
  CardMedia,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Badge,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  APPLICATION_LISTING_STATUS,
  APPLICATION_STATUS,
  POA_APPLICATION_STATUS,
  REJECTED_ROLES,
  USER_ROLES,
} from '../../utils/constant';
import {
  ApplicationStatusOrClassBasedOnRole,
  checkIfApplicationIsNonIndividual,
  displaySendBack,
  getFirstHolderName,
  isGetLinkVisible,
  handleDownload,
} from '../../utils/utilityFunctions';
import {
  ApplicationProps,
  investmentDetailsAplicationRef,
} from '../../redux-store/types/api-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAllApplications } from '../../redux-store/actions/application';
import { GetAllApplicantionsResponseBody } from '../../redux-store/types/application';
import { CommonLayoutForPOA, Footer } from '../commonComponents';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { ColumnType, DataTable } from '../DataTable';
import { useDebounce } from '../../utils/useDebounce';
import { RootStateType } from '../../redux-store/reducers';
import CloseIcon from '@mui/icons-material/Close';
import { formatShortDateAndTime } from '../../utils/date';
import { ApplicationRefs } from '../../redux-store/actions/onBoarding';
import { FilterDropdown, initialFilters } from '../investors/filters';
import { Distributor, getDistributorsListForFilters } from '../../redux-store/actions';
import { ApplicationFilterType } from '../../redux-store/types/api-types';

const getTableHeader = (role: string): ColumnType[] => {
  return [
    {
      header: 'Investor Name',
      valueGetter: ({ applicants = [] }: ApplicationProps) => getFirstHolderName(applicants),
    },
    {
      header: 'Application ID',
      label: 'applicationNumber',
    },
    {
      header: 'Mode of Holding',
      renderCell: (row: ApplicationProps) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}>
            {row.modeOfHolding || 'Single'}
          </Typography>
        );
      },
    },
    {
      header: 'Commited Value',
      valueGetter: (row: ApplicationProps) => row.commitmentAmount || 'N/A',
    },
    {
      header: 'Investor PAN',
      valueGetter: ({ applicants = [] }: ApplicationProps) => applicants[0]?.panNo || 'N/A',
    },
    {
      header: 'Distributor Name',
      label: 'viewDistributorRM',
      valueGetter: (row: ApplicationProps) =>
        row.distributor?.name || row.distributor?.arnCode || 'N/A',
    },
    // {
    //   header: 'Scheme Name',
    //   label: 'schemeName',
    //   valueGetter: (row: ApplicationProps) => (typeof row.scheme === 'string' ? row.scheme : 'N/A'),
    // },
    {
      header: 'Approval Status',
      renderCell: (row: ApplicationProps) => {
        const applicationStatus = ApplicationStatusOrClassBasedOnRole(row, role as USER_ROLES);
        return (
          <Typography
            className={applicationStatus}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: 14,
              color: '#B78813 !important',
              '&.Draft': {
                color: 'rgba(51, 127, 201, 0.7) !important',
              },
              '&.Completed': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7) !important',
              },
              '&.Rejected': {
                color: '#BD2932 !important',
                fontWeight: 500,
              },
              '& span': {
                display: 'block',
                color: 'rgba(51, 127, 201, 0.7) !important',
                mt: 0.5,
              },
            }}
            component={RouterLink}
            to={`/application-details/${row.id}`}>
            {`${applicationStatus}  ${displaySendBack(row)}`}
            {APPLICATION_LISTING_STATUS.sent_to_applicant1 === applicationStatus && (
              <span>Leegality Document {row.leegalityDocumentId ? 'generated' : 'pending'}</span>
            )}
          </Typography>
        );
      },
    },
  ];
};

export function ApplicationsListView({
  tableHeaders,
}: {
  tableHeaders: ColumnType[];
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [applicationsList, setApplicationsList] = useState<ApplicationProps[]>([]);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const {
    page,
    search: storeSearch,
    status: storeStatus,
    applicationType: storeApplicationType,
    modeOfHolding: storeModeOfHolding,
    distributorId: storeDistributorId,
    schemeId: storeSchemeId,
  } = useSelector((store: RootStateType) => store.paramsObj);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const [filters, setFilters] = useState<ApplicationFilterType>(
    initialFilters(
      role,
      storeStatus,
      storeApplicationType,
      storeModeOfHolding,
      storeDistributorId,
      storeSchemeId
    )
  );
  const { applicationType, distributorId, status, modeOfHolding, schemeId } = filters;
  const [noOfPages, setNoPages] = useState(1);
  const [currentPage, setPage] = useState(page);
  const [search, setSearch] = useState(storeSearch);
  // eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const debounceSearchString = useDebounce(search, 500);
  const [distributorLoading, setDistributorLoading] = useState(false);

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllApplications({
            page: currentPage,
            search,
            applicationType: applicationType.toString(),
            distributorId: distributorId.toString(),
            status: status.toString(),
            modeOfHolding: modeOfHolding.toString(),
            schemeId: schemeId.toString(),
          })
        )) as unknown as GetAllApplicantionsResponseBody;
        const { applications, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setApplicationsList(applications);
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
        setLoading(false);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString, filters]);

  // useEffect(() => {
  //   setPage(page);
  // }, [debounceSearchString]);

  const handleFiltersAnchorEl = async () => {
    try {
      setFiltersAnchorEl(true);
      setDistributorLoading(true);
      const distributorList = (await dispatch(
        getDistributorsListForFilters()
      )) as unknown as Distributor[];
      setDistributors(distributorList);
      setDistributorLoading(false);
    } catch (e) {
      console.error((e as Error).message);
      setDistributorLoading(false);
    }
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };
  const filterOptions = (): JSX.Element => {
    const badgeCount = ['applicationType', 'modeOfHolding', 'status', 'distributorId'].filter(
      (filterKey) => (filters[filterKey] as string[]).length
    ).length;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            distributors={distributors}
            loader={distributorLoading}
          />
        )}
      </>
    );
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box
      sx={{
        ml: { xs: 0 },
        p: { xs: 2, md: '50px' },
        borderRadius: '50px 0 0 0',
        bgcolor: { xs: '', md: 'rgba(238, 244, 251, 0.5)' },
        // minHeight: 'calc(100vh - 108px)',
      }}>
      <Box component="main">
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: 18,
              fontWeight: 500,
              mb: { xs: 2, md: 4 },
              mt: 10,
              p: { xs: '5px 15px', md: '' },
              borderRadius: '10px',
            }}>
            All Applications
          </Typography>
          <Grid
            container
            sx={{
              p: '20px 30px',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              bgcolor: 'common.white',
              mb: 1.5,
            }}
            rowGap={1.5}>
            <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2 }}>
              <Grid item>
                <FormControl variant="standard" sx={{ display: 'block' }}>
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                    Search Application
                  </InputLabel>
                  <Input
                    id="input-with-icon-adornment"
                    value={search}
                    onChange={({ target: { value } }) => {
                      setPage(1);
                      setSearch(value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchSharpIcon sx={{ color: 'primary.main' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 0.5 }}>
              {/* <FilterMenu /> */}
              {filterOptions()}
            </Grid>
          </Grid>
          <DataTable
            isLoading={isLoading}
            tableHeader={tableHeaders}
            tableData={applicationsList}
            noOfPages={noOfPages}
            currentPage={currentPage}
            paginationAction={handlePageChange}
            rowCustomStyles={{
              '&:last-child td, &:last-child th': { border: 0 },
              '.MuiTableCell-body ': { color: '#686e72' },
              background: 'transparent',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default function ApplicationEsignList(): JSX.Element {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [rejectedApplication, setRejectedApplication] = useState<ApplicationProps>();

  const [checkPoaOrNI, setCheckPoaOrNI] = useState(false);
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [id, setId] = useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [applicationRefsList, setApplicationRefsList] = useState<investmentDetailsAplicationRef[]>(
    []
  );
  const [tooltip, setTooltip] = useState('Click to Copy');

  const handleClose = () => {
    setOpen(false), setId('');
  };
  const handleDialogClose = () => {
    setDialogLoading(false);
    setOpen(true);
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialog(false);
  };
  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        if (id) {
          const responseApplicationRefsList = (await dispatch(
            ApplicationRefs(id)
          )) as unknown as investmentDetailsAplicationRef[];
          if (!componentIsAlive) {
            return;
          }
          setApplicationRefsList(responseApplicationRefsList);
          handleDialogClose();
        }
      } catch (e) {
        setDialogLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [id]);
  const rejectionCell = {
    header: '',
    renderCell: (row: ApplicationProps) => {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
            }}>
            {APPLICATION_STATUS[row.status] === 'Rejected' && (
              <IconButton>
                <CardMedia
                  component="img"
                  src="/images/comment.svg"
                  alt="comment"
                  sx={{ width: '20px' }}
                  onClick={() => {
                    setRejectedApplication(row);
                    setRejectionDialog(true);
                  }}
                />
              </IconButton>
            )}
          </Box>
        </>
      );
    },
  };
  const RefCell = {
    header: '',
    renderCell: (row: ApplicationProps) => {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
            }}>
            {[USER_ROLES.AMCAPPROVER, USER_ROLES.RTAAPPROVER].includes(role) && (
              <>
                {isGetLinkVisible(row.status) && (
                  <Tooltip title="Link">
                    <IconButton
                      sx={{
                        p: 0,
                        ':hover': {
                          bgcolor: '#F4FCFC',
                        },
                      }}
                      onClick={() => {
                        setId(row.id as string),
                          setDialogLoading(true),
                          setCheckPoaOrNI(row.hasPOA || checkIfApplicationIsNonIndividual(row));
                      }}>
                      <CardMedia
                        component="img"
                        src="/images/link-icon.svg"
                        alt="Link"
                        sx={{ width: '30px' }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {row?.signedLeegalityFile?.url && (
                  <Tooltip title={'Download'}>
                    <IconButton
                      sx={{
                        p: 0,
                        pl: 0.5,
                        pr: 0.5,
                        ':hover': {
                          bgcolor: '#F4FCFC',
                        },
                        cursor: 'pointer',
                        pointerEvents: 'all',
                      }}
                      onClick={async () => {
                        try {
                          // row?.signedLeegalityFile?.url && window.open(row?.signedLeegalityFile?.url)
                          row?.signedLeegalityFile?.url &&
                            (await handleDownload(
                              row?.signedLeegalityFile?.url as string,
                              `${row.applicationNumber}.pdf`
                            ));
                        } catch (e) {
                          console.error((e as Error).message);
                        }
                      }}>
                      <CardMedia
                        component="img"
                        src={'/images/download-icon.png'}
                        alt="Download"
                        //sx={{ width: '30px' }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Box>
        </>
      );
    },
  };
  return (
    <>
      <CommonLayoutForPOA />
      <ApplicationsListView tableHeaders={[...getTableHeader(role), rejectionCell, RefCell]} />
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={2}>
          <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
            Get Link
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        {applicationRefsList &&
          applicationRefsList.map((ref) => {
            return (
              <React.Fragment key={ref.id}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                  py={1}
                  sx={{
                    ':hover': {
                      bgcolor: '#F4FCFC',
                    },
                    ':last-of-type': {
                      mb: 5,
                    },
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '18px',
                      fontWeight: 500,
                    }}>
                    Copy Link For {ref.name}
                  </Typography>
                  <Tooltip title={tooltip}>
                    <IconButton
                      onMouseOver={() => {
                        setTooltip('Copy Link');
                      }}
                      onClick={() => {
                        setTooltip('Copied'),
                          navigator.clipboard.writeText(
                            checkPoaOrNI
                              ? ref.invitationUrl
                              : ref?.signatureBy &&
                                ('fundManager' === ref.signatureBy ||
                                  USER_ROLES.TRUSTEE === ref.signatureBy)
                              ? ref.invitationUrl
                              : `${window.location.origin}/investment-details/${ref.referenceId}/details`
                          );
                      }}>
                      <CardMedia
                        component="img"
                        src="/images/link-icon.svg"
                        alt="Link"
                        sx={{ width: '35px' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </React.Fragment>
            );
          })}
      </Dialog>
      <Dialog
        onClose={handleDialogClose}
        open={isDialogLoading}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
            },
          }}>
          <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
            Loading...
          </Typography>
        </Box>
      </Dialog>

      <Dialog
        onClose={handleRejectionDialogClose}
        open={rejectionDialog}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <IconButton
          onClick={handleRejectionDialogClose}
          sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        {rejectedApplication && (
          <Box
            sx={{
              width: { xs: '80%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontWeight: 500,
              },
              mt: 1,
              mb: 5,
            }}>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 6.7 },
                mt: 1,
              }}>
              Application Id:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.applicationNumber}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 1.5 },
                mt: 1,
              }}>
              Application Status:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#BD2932',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {POA_APPLICATION_STATUS[rejectedApplication.status]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9 },
                mt: 1,
              }}>
              Rejected on:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {formatShortDateAndTime(rejectedApplication.rejectedAt)}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9.5 },
                mt: 1,
              }}>
              Rejected by:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {REJECTED_ROLES[rejectedApplication.rejectedByRole]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                mt: 1,
              }}>
              Reason for rejection:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.rejectedReason}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: '32%', lineHeight: 1.1, width: '30%', mt: 3 }}
              onClick={handleRejectionDialogClose}>
              Done
            </Button>
          </Box>
        )}
      </Dialog>
      <Footer />
    </>
  );
}
