// eslint-disable-next-line no-use-before-define
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { RootStateType } from '../redux-store/reducers';

export type AuthRoutePropsType = {
  path: string;
  // eslint-disable-next-line
  component: React.ComponentType<any>;
  isLoggedIn: boolean;
  exact?: boolean;
  role?: string;
  acceptedRoles?: string[];
  redirectedRoute?: string;
  // eslint-disable-next-line
  [key: string]: string | boolean | string[] | React.ComponentType<any> | undefined;
};

function ProtectedRoute({
  component: Component,
  isLoggedIn,
  role = '',
  acceptedRoles,
  isProtectedRouteForRefLogin = false,
  redirectedRoute = '',
  refLoggedIn,
  ...rest
}: // eslint-disable-next-line no-undef
AuthRoutePropsType): JSX.Element {
  return isProtectedRouteForRefLogin ? (
    <Route
      {...rest}
      render={({ location }) =>
        refLoggedIn ? (
          <Component {...rest} />
        ) : (
          <Redirect to={{ pathname: redirectedRoute, state: { from: location } }} />
        )
      }
    />
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          acceptedRoles?.includes(role) ? (
            <Component {...rest} />
          ) : (
            <Redirect to={{ pathname: '/not-authorized' }} />
            // <Route
            //   path="*"
            //   render={() => {
            //     return `You are not authorized to view this page`;
            //   }}
            // />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
}
export default connect(
  ({ auth, investor }: RootStateType) => ({
    isLoggedIn: !!auth.token,
    role: auth.role,
    refLoggedIn: !!investor.token,
  }),
  {}
)(ProtectedRoute);
