import * as React from 'react';
import { IconButton, Button, FormHelperText, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { addBulkUsers } from '../../redux-store/actions/userManagement';
import { reportDownload } from '../Reports/report-utils';
import { showError } from '../../redux-store/actions/auth';
import { addBulkFunds } from '../../redux-store/actions/funds';

export default function BulkUploadFilePond({
  bulkTemplateType,
}: {
  bulkTemplateType: string;
}): JSX.Element {
  const dispatch = useDispatch();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = React.useState('Please upload your file here');
  const [loader, setLoading] = React.useState(false);

  const [upload, setUpload] = React.useState(false);

  React.useEffect(() => {
    if (!upload) {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [upload]);

  // eslint-disable-next-line
  const handleFileChange = async (event: any) => {
    try {
      setFileName(event.target.files[0].name);
      setUpload(!upload);
      const validTypes = [
        '.xlsx',
        '.xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];
      const fileType = event.target.files[0].type;
      if (!validTypes.includes(fileType)) {
        setUpload(false);
        throw 'The file type is not supported';
      }
      const file = event.target.files[0];
      setLoading(true);
      if (bulkTemplateType === 'user') {
        // eslint-disable-next-line
        const responseUser = (await dispatch(addBulkUsers(file))) as unknown as any;
        reportDownload(responseUser as unknown as Blob, 'updated-users-list.xlsx');
      }
      if (bulkTemplateType === 'fund') {
        // eslint-disable-next-line
        const responseFund = (await dispatch(addBulkFunds(file))) as unknown as any;
        reportDownload(responseFund as unknown as Blob, 'updated-fund-plan-list.xlsx');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant="outlined"
          component="label"
          fullWidth
          startIcon={!upload && <FileUploadOutlinedIcon sx={{ fontSize: '25px !important' }} />}
          sx={{ color: '#4F4F4F', pointerEvents: upload ? 'none' : 'unset' }}>
          {upload ? fileName : 'Please upload your file here'}
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            hidden
            onChange={handleFileChange}
          />
        </Button>
        {loader && (
          <Box sx={{ display: 'flex', ml: 2, mt: 1 }}>
            <CircularProgress size={30} />
          </Box>
        )}
        <IconButton
          onClick={() => setUpload(!upload)}
          sx={{
            display: upload && !loader ? 'flex' : 'none',
            ':hover': {
              background: 'none',
            },
          }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormHelperText sx={{ fontSize: 14, color: '#8692A6', mt: 1 }}>
        File format should be Excel
      </FormHelperText>
    </>
  );
}
