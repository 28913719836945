import { Box, Button, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Item, ItemDetails } from './index';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { SubHeading } from '../investors/components';
import { ENABLE_KRA_DOCUMENT_FETCH, USER_ROLES } from '../../utils/constant';
import { checkKraBasedOnStatus } from '../../utils/utilityFunctions';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { styles } from '../../Styles/styles';

export const getFieldTitle: { [key: string]: string } = {
  addressProof: 'Address Proof',
  identityProof: 'Identity Proof',
  bankAccountProof: 'Bank Account Proof',
  poaNotarized: 'Poa Notarized Document',
  investorsConsent: 'Investors Consent Document',
  fatca: 'Fatca Document',
  form_10ff_trc: 'Form 10F/TRC',
  additional: 'Additional Document',
  investorPhotograph: 'Investor Photograph',
  investorSignature: 'Investor Signature',
  compliance_document: 'Compliance Document',
  pioCard: 'PIO CARD',
  overseaseAddressProof: 'Overseas Address Proof',
  passportCopy: 'Passport Copy ( 1st and last page) and Visa Copy',
  nomineeIdProof: 'Nominee ID Proof',
  guardianIdProof: 'Guardian ID Proof',
  correspondenceAddress: 'Correspondence Address Proof',
  panCard: 'PAN Card',
  aadharCard: 'Aadhar Card',
  proofOfRelation: 'Proof of Relationship with Guardian',
  proofOfCourt: 'Proof of Court or Adoptive Decree',
  riskProfile: 'Risk Metrics',
  riskDeviationLetter: 'Risk Deviation Letter',
  riskDisclosure: 'Risk Disclosure',
};

export default function DocumentDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicants, nominees } = application;
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Document Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  {applicant.documents && applicant.documents.length !== 0 && (
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  )}
                  {ENABLE_KRA_DOCUMENT_FETCH &&
                    USER_ROLES.INVESTOR !== role &&
                    applicant.documents &&
                    applicant.documents.length === 0 &&
                    checkKraBasedOnStatus(application.status) && (
                      <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                    )}
                  {ENABLE_KRA_DOCUMENT_FETCH && USER_ROLES.INVESTOR !== role
                    ? applicant.documents &&
                      applicant.documents.length === 0 &&
                      index === 0 &&
                      !applicant.kraDocument &&
                      !checkKraBasedOnStatus(application.status) && (
                        <Grid
                          xs={12}
                          sm={12}
                          sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'common.black',
                            textAlign: 'center',
                            my: 3,
                          }}>
                          No Documents
                        </Grid>
                      )
                    : applicant.documents &&
                      applicant.documents.length === 0 &&
                      index === 0 && (
                        <Grid
                          xs={12}
                          sm={12}
                          sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'common.black',
                            textAlign: 'center',
                            my: 3,
                          }}>
                          No Documents
                        </Grid>
                      )}
                  <Grid
                    container
                    pt={applicant.documents && applicant.documents.length && 2}
                    pb={applicant.documents && applicant.documents.length && 5}
                    columnSpacing={13}
                    sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                    {applicant.documents &&
                      applicant.documents.map((document, index: number) => {
                        return (
                          <React.Fragment key={document.id}>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={document.file ? 6 : 12}
                              sx={{ mt: index !== 0 ? 6 : 0 }}>
                              <Item>{getFieldTitle[document.documentType]}</Item>
                              {document.documentType === 'investorSignature' && (
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}>
                                  (This signature copy is used as a verification process for future
                                  redemptions and transfer requests)
                                </Typography>
                              )}
                              <ItemDetails>
                                {document.documentName?.toLowerCase() ===
                                getFieldTitle[document.documentType]?.toLowerCase()
                                  ? document.file?.name
                                  : document.documentName}
                              </ItemDetails>
                            </Grid>
                            {document.file && (
                              <Grid item xs={12} sm={6}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                    flexDirection: { sm: 'column', xs: 'row', md: 'row' },
                                  }}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      color: 'common.white',
                                      // minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                      minWidth: '70%',
                                      // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                      fontWeight: 600,
                                      lineHeight: 1.5,
                                    }}
                                    onClick={() => window.open(document.file?.viewUrl)}>
                                    View
                                  </Button>
                                  <Tooltip title={'Download'}>
                                    <IconButton
                                      sx={{
                                        // p: 0,
                                        // pl: 1.5,
                                        ':hover': {
                                          bgcolor: '#F4FCFC',
                                        },
                                        cursor: 'pointer',
                                        pointerEvents: 'all',
                                        // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                      }}
                                      onClick={() => window.open(document.file?.url)}>
                                      <CardMedia
                                        component="img"
                                        src={'/images/download-icon.png'}
                                        alt="Download"
                                        sx={{ width: '25px' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                    {ENABLE_KRA_DOCUMENT_FETCH &&
                      USER_ROLES.INVESTOR !== role &&
                      checkKraBasedOnStatus(application.status) &&
                      (applicant.kraDocument && applicant.kraDocument.length > 0 ? (
                        <>
                          {applicant.kraDocument.map((document, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={document ? 6 : 12}
                                  sx={{
                                    mt:
                                      applicant.documents && applicant.documents.length === 0
                                        ? 0
                                        : 6,
                                  }}>
                                  <Item>{'KRA Documents'}</Item>
                                  <ItemDetails>{document.name}</ItemDetails>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      color: 'common.white',
                                      minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                      mt:
                                        applicant.documents && applicant.documents.length === 0
                                          ? 2
                                          : { sm: 8, xs: 2 },
                                      fontWeight: 600,
                                      lineHeight: 1.5,
                                    }}
                                    onClick={() => window.open(document.url)}>
                                    Download
                                  </Button>
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <React.Fragment>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={document ? 6 : 12}
                              sx={{
                                mt: applicant.documents && applicant.documents.length === 0 ? 0 : 6,
                              }}>
                              <Item>{'KRA Documents'}</Item>
                              <ItemDetails>{'KRA Documents in Progress'}</ItemDetails>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Button
                                variant="contained"
                                sx={{
                                  color: 'common.white',
                                  minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                  mt:
                                    applicant.documents && applicant.documents.length === 0
                                      ? 2
                                      : { sm: 8, xs: 2 },
                                  fontWeight: 600,
                                  lineHeight: 1.5,
                                }}
                                disabled={true}
                                // onClick={() => window.open(document.url)}
                              >
                                Download
                              </Button>
                            </Grid>
                          </React.Fragment>
                        </>
                      ))}
                  </Grid>
                </React.Fragment>
              );
            })}
            {nominees.length !== 0 &&
              nominees.map((nominee, index: number) => {
                const { nomineedocuments = [] } = nominee;
                const title = [
                  'First Nominee Details',
                  'Second Nominee Details',
                  'Third Nominee Details',
                ][index];
                return (
                  <React.Fragment key={nominee.id}>
                    {nomineedocuments && nomineedocuments.length !== 0 && (
                      <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                    )}
                    <Grid
                      container
                      pt={nomineedocuments && nomineedocuments.length && 2}
                      pb={nomineedocuments && nomineedocuments.length && 5}
                      columnSpacing={13}
                      sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                      {nomineedocuments &&
                        nomineedocuments.map((document, index: number) => {
                          return (
                            <React.Fragment key={document.id}>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={document.file ? 6 : 12}
                                sx={{ mt: index !== 0 ? 6 : 0 }}>
                                <Item>{getFieldTitle[document.documentType]}</Item>
                                <ItemDetails>
                                  {document.documentName?.toLowerCase() ===
                                  getFieldTitle[document.documentType]?.toLowerCase()
                                    ? document.file?.name
                                    : document.documentName}
                                </ItemDetails>
                              </Grid>
                              {document.file && (
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                      flexDirection: { sm: 'column', xs: 'row', md: 'row' },
                                    }}>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        color: 'common.white',
                                        // minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                        minWidth: '70%',
                                        // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                        fontWeight: 600,
                                        lineHeight: 1.5,
                                      }}
                                      onClick={() => window.open(document.file?.viewUrl)}>
                                      View
                                    </Button>
                                    <Tooltip title={'Download'}>
                                      <IconButton
                                        sx={{
                                          // p: 0,
                                          // pl: 1.5,
                                          ':hover': {
                                            bgcolor: '#F4FCFC',
                                          },
                                          cursor: 'pointer',
                                          pointerEvents: 'all',
                                          // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                        }}
                                        onClick={() => window.open(document.file?.url)}>
                                        <CardMedia
                                          component="img"
                                          src={'/images/download-icon.png'}
                                          alt="Download"
                                          sx={{ width: '25px' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </Grid>
                  </React.Fragment>
                );
              })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
