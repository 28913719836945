import { Box, Grid, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { RmsType, addRmDetails } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Location } from 'history';
import { addRM, editRM, getRMById } from '../../redux-store/actions/userManagement';
import { userManagementRmSchema } from '../../utils/schema';
// eslint-disable-next-line
import { CountryCodesDropDown } from '../commonComponents';
import { AddressFieldEditable } from './AddressFields';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { nonDigitRegex } from '../../utils/regex';
import { RoleTypes } from '../../utils/constant';
import MFSelectField from '../../lib/formik/SelectField';

const initialValues: addRmDetails = {
  name: '',
  email: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  phone: '',
  panNumber: '',
  empCode: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  isActive: true,
  roleType: '',
};

export default function InviteRmDetails({
  location,
}: {
  location: Location<{ rmId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [formikValues, setFormikValues] = useState<addRmDetails | null>();
  const { rmId } = location.state || { rmId: '' };
  const [rmDetails, setRmDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (rmId) {
          const getRmDetails = (await dispatch(getRMById(rmId))) as unknown as RmsType;
          const {
            name,
            panNumber,
            empCode,
            pincode,
            city,
            state,
            country,
            streetName,
            buildingNo,
            id,
            isActive,
            roleType,
          } = getRmDetails;
          const { phone, email, countryNameAndCode, countryCode } = getRmDetails.user;
          setRmDetails({
            ...rmDetails,
            name,
            panNumber,
            empCode,
            pincode,
            city,
            state,
            country,
            streetName,
            buildingNo,
            id,
            phone,
            email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            roleType,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: addRmDetails) => {
    try {
      if (!rmId) {
        await dispatch(
          addRM({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() })
        );
        history.push(`relationship-managers`);
      } else {
        await dispatch(
          editRM(rmId, { ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() })
        );
        history.push(`relationship-manager-details`, { rmId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={rmDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={userManagementRmSchema}>
        {({ handleSubmit, setFieldValue }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={rmId ? 'RM Details' : 'Onboarding RM Details'}
              onClickBack={() =>
                rmId
                  ? history.push(`relationship-manager-details`, { rmId })
                  : history.push('relationship-managers')
              }
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="name" label="RM Name *" placeholder="Enter RM name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="roleType"
                      label="Role Type *"
                      items={Object.keys(RoleTypes).map((role) => ({
                        key: RoleTypes[role],
                        value: role,
                      }))}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="Pan"
                      placeholder="Enter Pan"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="empCode" label="Emp Code" placeholder="Enter Emp Code" />
                  </Grid>
                  <AddressFieldEditable />
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
