const data = {
  barOptions: {
    responsive: true,
    barPercentage: 0.3,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          borderDash: [4],
        },
      },
    },

    plugins: {
      title: {
        display: true,
        text: 'Month on Month - last four Months Onboarding count',
      },
      legend: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        mode: 'index',
        intersect: false,
        // eslint-disable-next-line
        itemSort: function (a: any, b: any) {
          return b.datasetIndex - a.datasetIndex;
        },
        callbacks: {
          title: (): string => '',
          // eslint-disable-next-line
          label: (item: any) => {
            return `${item.dataset.label} ${item.raw}`;
          },
          // eslint-disable-next-line
          labelTextColor: (item: any) => {
            return item.dataset.backgroundColor;
          },
        },
        displayColors: false,
        backgroundColor: '#F8F8F8',
      },
    },
  },
  barData: {
    labels: ['Sept', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'NRI',
        data: [50, 30, 35, 40],
        backgroundColor: 'rgb(78, 215, 149)',
      },
      {
        label: 'Minor',
        data: [146, 120, 170, 110],
        backgroundColor: '#ff0000',
      },

      {
        label: 'Non-Individual',
        data: [20, 30, 40, 40],
        backgroundColor: 'rgb(245, 91, 118)',
      },
      {
        label: 'Individual',
        data: [146, 120, 170, 110],
        backgroundColor: 'rgb(103, 173, 242)',
      },
    ],
  },
  lineBarData: {
    labels: [],
    lablesHeight: [18, 10],
    // barPercentage: 2,
    // showLine:true,

    datasets: [
      {
        type: 'bar',
        label: 'Total Commited Value (in Cr)',
        borderColor: 'rgb(97, 209, 214)',
        // fill: false,
        data: [],
        backgroundColor: 'rgb(97, 209, 214)',
        // borderWidth: 0,
        order: 2,
      },
      {
        type: 'line',
        label: 'Total Contribution Value (in Cr)',
        data: [],
        fill: false,
        borderColor: 'black',
        backgroundColor: 'black',
        pointBackgroundColor: 'black',
        pointRadius: 3,
        order: 1,
      },
    ],
  },
  lineBarOptions: {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 40,
          boxHeight: 2,
          // useLineStyle: true
        },
      },
      tooltip: {
        yAlign: 'bottom',
        displayColors: true,
        backgroundColor: '#F8F8F8',
        callbacks: {
          title: (): string => '',
          // eslint-disable-next-line
          label: (item: any) => {
            return `  ${item.raw} Crore`;
          },
          // labelTextColor: (item: any) => {
          //   return item.dataset.backgroundColor;
          // },
          // eslint-disable-next-line
          labelTextColor: (item: any) => {
            return 'black';
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [2, 2],
        },
      },
    },
    barPercentage: 0.5,
    borderWidth: 2,
    pointRadius: 0,
    pointHitRadius: 2,
  },
  donutData: {
    labels: ['Completed', 'In Progress'],
    datasets: [
      {
        data: [],
        backgroundColor: ['rgb(182, 234, 237)', 'rgb(97, 209, 214)'],
        hoverOffset: 4,
      },
    ],
  },
  donutOptions: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        responsive: true,
        display: true,
        text: 'Number of Applications',
        align: 'start',
      },
      legend: {
        display: true,
        position: 'right',
        onClick: null,
        labels: {
          boxWidth: 23,
          boxHeight: 7,
          font: {
            size: 12,
            weight: 'bold',
          },
          padding: 20,
        },
      },
      backgroundColor: '#F8F8F8',
      centerTitle: {
        display: false,
        text: [''],
        width: 100,
      },
    },
    cutout: 60,
  },
};
export default data;
