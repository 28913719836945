import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Hidden,
  Stack,
  Switch,
} from '@mui/material';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { editDistributor, getDistributorsList } from '../../redux-store/actions/userManagement';
import { AddDistributorRequestBody, DistributorListType } from '../../redux-store/types/api-types';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from '../DataTable';
import { Location } from 'history';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { DistributorTypesForUserManageMent } from '../../utils/constant';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import { styles } from '../../Styles/styles';
import { SearchUser } from './SearchUser';

const headersCompany = [
  {
    header: 'Distributor Name',
    renderCell: (row: DistributorListType) => {
      return (
        <Typography
          variant="h5"
          sx={{
            color: '#61D1D6',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: '14px',
            ':hover': {
              color: 'primary.main',
            },
          }}
          component={RouterLink}
          to={{ pathname: 'distributor-details', state: { distributorId: row.id } }}>
          {row.name || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Company PAN',
    valueGetter: (row: DistributorListType) => row.panNumber || 'N/A',
  },
  {
    header: 'ARN Code',
    label: 'arnCode',
  },
  {
    header: ' ',
    label: 'viewDistributorRM',
  },
];
const headersIndividual = [
  {
    header: 'Distributor Name',
    renderCell: (row: DistributorListType) => {
      return (
        <Typography
          variant="h5"
          sx={styles.UsersClickbleColumn}
          component={RouterLink}
          to={{ pathname: 'distributor-details', state: { distributorId: row.id } }}>
          {row.name || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Email id',
    valueGetter: (row: DistributorListType) => row.user?.email || 'N/A',
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: DistributorListType) => row.user?.phone || 'N/A',
  },
  {
    header: 'ARN Code',
    valueGetter: (row: DistributorListType) => row.arnCode || 'N/A',
  },
  {
    header: 'RM name',
    valueGetter: (row: DistributorListType) => row.rm?.name || 'N/A',
  },
];

function Distributors({
  location,
}: {
  location: Location<{ distributorType: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributorType, setDistributerType] = useState(
    location.state?.distributorType ? location.state.distributorType : 'company'
  );
  const [distributorData, setDistributorData] = useState<DistributorListType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    distributorRowData: AddDistributorRequestBody;
  } | null>(null);
  const [search, setSearch] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setDistributerType(event.target.value);
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);

        const res =
          distributorType === DistributorTypesForUserManageMent.Individual
            ? ((await dispatch(
                getDistributorsList({ type: distributorType })
              )) as unknown as DistributorListType[])
            : ((await dispatch(
                getDistributorsListForFilters({ type: distributorType })
              )) as unknown as DistributorListType[]);

        if (!isComponentActive) return;
        setDistributorData(res);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) {
          setIsLoading(false);
        }
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [distributorType]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const IndividualAccessViewOrEditCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddDistributorRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ distributorRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: DistributorListType): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               // onClick={() => {
    //               //  history.push(`/application`, { id: row.id });
    //               // }}
    //               component={RouterLink}
    //               to={{ pathname: 'edit-distributor', state: { distributorId: row.id } }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-distributor', state: { distributorId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const CompanyAccessViewOrEditCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddDistributorRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ distributorRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },

    {
      header: '',
      renderCell: (row: DistributorListType): JSX.Element => {
        return (
          <Typography
            variant="h5"
            component={RouterLink}
            to={{ pathname: 'distributor', state: { distributorId: row.id } }}
            sx={styles.UsersClickbleColumn}>
            View Details Of Mapped Users
          </Typography>
        );
      },
    },

    // {
    //   header: '',
    //   renderCell: (row: DistributorListType): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               // onClick={() => {
    //               //  history.push(`/application`, { id: row.id });
    //               // }}
    //               component={RouterLink}
    //               to={{ pathname: 'edit-distributor', state: { distributorId: row.id } }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-distributor', state: { distributorId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <SearchUser search={search} setSearch={setSearch} placeholder={'Search Distributor'} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
            flexDirection: { lg: 'row', xs: 'column' },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500, mb: { xs: 1, sm: 0 } }}>
            List of Distributors
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: 'space-between',
            }}>
            <Select
              sx={{ mr: { xs: 0, sm: 4 } }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={distributorType}
              onChange={handleChange}>
              {Object.keys(DistributorTypesForUserManageMent).map((item) => (
                <MenuItem
                  value={DistributorTypesForUserManageMent[item]}
                  key={DistributorTypesForUserManageMent[item]}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Hidden only="xs">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  mr: 2.5,
                  py: 0.7,
                  px: 3,
                }}
                onClick={() =>
                  history.push('add-distributor', { typeOfDistributor: distributorType })
                }>
                Add Distributor
              </Button>
            </Hidden>
            <Hidden smUp={true}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 13,
                  //mr: 2.5,
                  py: 0.5,
                  // px: { xs: 1, sm: 3 },
                  // whiteSpace: 'nowrap',
                }}
                onClick={() =>
                  history.push('add-distributor', { typeOfDistributor: distributorType })
                }>
                Add Distributor
              </Button>
            </Hidden>
          </Box>
        </Box>
        <Grid item xs={12} sm={12} lg={12}>
          {useMemo(
            () => (
              <DataTable
                tableData={distributorData?.filter(
                  (item) =>
                    item.name?.toLowerCase()?.includes(search.toLowerCase()) ||
                    item.user?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
                    item.user?.phone?.toLowerCase()?.includes(search.toLowerCase())
                )}
                tableHeader={
                  distributorType === 'company'
                    ? [...headersCompany, ...CompanyAccessViewOrEditCell]
                    : [...headersIndividual, ...IndividualAccessViewOrEditCell]
                }
                isLoading={isLoading}
                currentPage={currentPage}
                noOfPages={1}
                paginationAction={handlePageChange}
                renderAdditionalRow={true}
                tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
                tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
              />
            ),
            [distributorType, isLoading, distributorData, search]
          )}
        </Grid>
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.distributorRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                editDistributor(openDialog.distributorRowData.id as number, {
                  ...openDialog.distributorRowData,
                  isActive: !openDialog?.distributorRowData.isActive,
                })
              )) as unknown as DistributorListType;
              setDistributorData(
                distributorData?.map((distributor) => {
                  if (Number(distributor.id) === Number(updatedResponse.id)) {
                    return {
                      ...distributor,
                      ...updatedResponse,
                    };
                  }
                  return distributor;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default Distributors;
