import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import { CommonLayout } from '../commonComponents';
import FundDetails from './fundDetail';
import AddFund from './addFund';
import ViewPlans from './viewPlans';
import AddPlan from './addPlan';
import FundsList from './fundList';
import PlanDetails from './planDetail';
import { IdDependencyRoutes } from '../userManagement/router';
import LoginRequireRoute from '../../routes/LoginRequireRoute';

// eslint-disable-next-line
function Routes({ historyLocationState }: { historyLocationState: any }): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${path}/fund-list`} />} />
        <Route path={`${path}/add-fund`} component={AddFund} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/add-plan`}
          component={AddPlan}
          replaceUrl={'/funds-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/fund-details`}
          component={FundDetails}
          replaceUrl={'/funds-setup'}
        />
        <Route path={`${path}/fund-list`} component={FundsList} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/view-plans`}
          component={ViewPlans}
          replaceUrl={'/funds-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/plan-details`}
          component={PlanDetails}
          replaceUrl={'/funds-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-fund`}
          component={AddFund}
          replaceUrl={'/funds-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-plan`}
          component={AddPlan}
          replaceUrl={'/funds-setup'}
        />
        <LoginRequireRoute path="*" />
      </Switch>
    </Router>
  );
}

export const FundsSetup = (): JSX.Element => {
  const history = useHistory();
  return (
    <CommonLayout>
      <>
        <Routes historyLocationState={history.location.state} />
      </>
    </CommonLayout>
  );
};
