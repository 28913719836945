/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../middleware';
import {
  GET_DISTRIBUTOR_CODES,
  GET_DISTRIBUTOR_CODES_SUCCESS,
  GET_IFSC_DETAILS_SUCCESS,
  GET_IFSC_DETAILS,
  GET_DISTRIBUTORS_SUCCESS,
  GET_DISTRIBUTORS,
  IfscType,
  GET_RMS,
  GET_RMS_SUCCESS,
  GET_Countries_SUCCESS,
  GET_Countries,
  nationaliyType,
  GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS,
  GET_DISTRIBUTORS_FOR_FILTERS,
  GET_JOINTHOLDERLIST_SUCCESS,
  GET_JOINTHOLDERLIST,
  GET_RMTEMPLATE_SUCCESS,
  GET_RMTEMPLATE,
  GET_INDIVIDUALDISTRIBUTORTEMPLATE_SUCCESS,
  GET_INDIVIDUALDISTRIBUTORTEMPLATE,
  GET_COMPANYDISTRIBUTORTEMPLATE_SUCCESS,
  GET_COMPANYDISTRIBUTORTEMPLATE,
  GET_DISTRIBUTORRMTEMPLATE_SUCCESS,
  GET_DISTRIBUTORRMTEMPLATE,
  GET_AUTHORISEDSIGNATORYTEMPLATE_SUCCESS,
  GET_AUTHORISEDSIGNATORYTEMPLATE,
  GET_POAAPPROVERTEMPLATE_SUCCESS,
  GET_POAAPPROVERTEMPLATE,
  GET_TRUSTEETEMPLATE_SUCCESS,
  GET_TRUSTEETEMPLATE,
  GET_IMTEMPLATE_SUCCESS,
  GET_IMTEMPLATE,
  GET_AIFAPPROVERTEMPLATE_SUCCESS,
  GET_AIFAPPROVERTEMPLATE,
  GET_AMCADMINTEMPLATE_SUCCESS,
  GET_AMCADMINTEMPLATE,
  GET_PLANTEMPLATE_SUCCESS,
  GET_PLANTEMPLATE,
  GET_FUNDTEMPLATE_SUCCESS,
  GET_FUNDTEMPLATE,
  GET_RISKPROFILEMDMS_SUCCESS,
  GET_RISKPROFILEMDMS,
  GET_AMCAPPROVERTEMPLATE_SUCCESS,
  GET_AMCAPPROVERTEMPLATE,
} from '../types/mdms';
import {
  JointholderRelationMaster,
  RiskProfileMaster,
  RMType,
  directDistrubutorType,
  supportSubdistibutorDetailsType,
} from '../types/api-types';

export type Distributor = {
  id: number;
  userId: number;
  name: string;
  firstName?: string;
  type: string;
  buildingNo: string;
  streetName: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  arnCode: string;
  panNumber: string;
  isActive: boolean | null;
  amcId: number[];
  rmId: number;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    countryCode: string;
    phone: string;
    email: string;
    role: string;
    isActive: boolean;
    password: string | null;
  };
  subdistributorType: string;
  subdistributors?: SubDistributor[];
};

export type SubDistributor = Distributor & {
  distributors_RM_Code: string;
  supportSubdistributorId: string;
  supportSubdistributorDetails: supportSubdistibutorDetailsType;
  directSubdistributorsDetails: directDistrubutorType;
};
export type getDistributorsResponse = {
  userId: number;
  empCode: string;
  amcId: number;
  createdAt: string;
  updatedAt: string;
  address1: string;
  address2: string;
  address3: string;
  arnCode: string;
  id: number;
  panNumber: string;
  phone: string;
  role: string;
  firstName: string;
  distributors: Distributor[];
};

//To get distributors under RM
export const getDistributors =
  (params?: { isActive: boolean }) =>
  async (dispatch: any): Promise<getDistributorsResponse> => {
    const distributorsResponse = await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors-of-rm`,
        method: 'GET',
        types: [GET_DISTRIBUTOR_CODES_SUCCESS, GET_DISTRIBUTOR_CODES],
        params,
      },
    });
    return distributorsResponse as getDistributorsResponse;
  };

export const getIFSCDetails =
  (ifscCode: string) =>
  async (dispatch: any): Promise<IfscType> => {
    const ifscResponse = await dispatch({
      [CALL_API]: {
        url: `/onboarding/ifsc/${ifscCode}`,
        method: 'GET',
        types: [GET_IFSC_DETAILS_SUCCESS, GET_IFSC_DETAILS],
        slient: true,
      },
    });
    return ifscResponse as IfscType;
  };

//To get all the distributors list who's status is active
export const getDistributorsList =
  () =>
  async (dispatch: any): Promise<Distributor[]> => {
    const distributorsResponse = (await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors`,
        method: 'GET',
        types: [GET_DISTRIBUTORS_SUCCESS, GET_DISTRIBUTORS],
      },
    })) as Distributor[];
    return distributorsResponse as Distributor[];
  };

// Get the list of RMs under and return all the active rms

export type RMListFilter = {
  name?: string;
  limit?: number;
  page?: number;
  orderBy?: string;
  orderDirection?: string;
  roleType?: string;
  isActive?: boolean;
};
export const getRMsList =
  (params?: RMListFilter) =>
  async (dispatch: any): Promise<RMType[]> => {
    const rmListRes = (await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/rm-list`,
        method: 'GET',
        types: [GET_RMS, GET_RMS_SUCCESS],
        params,
      },
    })) as { rms: RMType[]; limit: 10; page: 1; pages: 1; total: 1 };
    return rmListRes.rms as RMType[];
  };

export const getNationalityList =
  () =>
  async (dispatch: any): Promise<nationaliyType> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/default-countries.json',
        method: 'GET',
        types: [GET_Countries_SUCCESS, GET_Countries],
      },
    });
  };

export const getDistributorsListForFilters =
  (params?: { type?: string; isActive?: boolean }) =>
  async (dispatch: any): Promise<Distributor[]> => {
    const distributorsResponse = (await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors/list`,
        method: 'GET',
        types: [GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS, GET_DISTRIBUTORS_FOR_FILTERS],
        params,
      },
    })) as Distributor[];
    return distributorsResponse as Distributor[];
  };

export const jointHolderRelationsList =
  () =>
  async (dispatch: any): Promise<JointholderRelationMaster> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/jointholder_relations_master.json',
        method: 'GET',
        types: [GET_JOINTHOLDERLIST_SUCCESS, GET_JOINTHOLDERLIST],
      },
    });
  };

export const rmTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/rm.xlsx',
        method: 'GET',
        types: [GET_RMTEMPLATE_SUCCESS, GET_RMTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const planTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/plan.xlsx',
        method: 'GET',
        types: [GET_PLANTEMPLATE_SUCCESS, GET_PLANTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const fundTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/scheme.xlsx',
        method: 'GET',
        types: [GET_FUNDTEMPLATE_SUCCESS, GET_FUNDTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const riskProfileMasterData =
  () =>
  async (dispatch: any): Promise<RiskProfileMaster> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/riskprofile_master.json',
        method: 'GET',
        types: [GET_RISKPROFILEMDMS_SUCCESS, GET_RISKPROFILEMDMS],
      },
    });
  };

export const individualDistributorTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/distributor_individual.xlsx',
        method: 'GET',
        types: [GET_INDIVIDUALDISTRIBUTORTEMPLATE_SUCCESS, GET_INDIVIDUALDISTRIBUTORTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const companyDistributorTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/distributor_company.xlsx',
        method: 'GET',
        types: [GET_COMPANYDISTRIBUTORTEMPLATE_SUCCESS, GET_COMPANYDISTRIBUTORTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const distributorRMTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/distributor_rm.xlsx',
        method: 'GET',
        types: [GET_DISTRIBUTORRMTEMPLATE_SUCCESS, GET_DISTRIBUTORRMTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const authorisedSignatoryTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/authorised_signatory.xlsx',
        method: 'GET',
        types: [GET_AUTHORISEDSIGNATORYTEMPLATE_SUCCESS, GET_AUTHORISEDSIGNATORYTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const poaApproverTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/poa_approver.xlsx',
        method: 'GET',
        types: [GET_POAAPPROVERTEMPLATE_SUCCESS, GET_POAAPPROVERTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const trusteeTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/trustee.xlsx',
        method: 'GET',
        types: [GET_TRUSTEETEMPLATE_SUCCESS, GET_TRUSTEETEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const investmentManagerTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/fund_manager.xlsx',
        method: 'GET',
        types: [GET_IMTEMPLATE_SUCCESS, GET_IMTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const rtaApproverTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/rta_approver.xlsx',
        method: 'GET',
        types: [GET_AIFAPPROVERTEMPLATE_SUCCESS, GET_AIFAPPROVERTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };
export const amcApproverTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/aif_approver.xlsx',
        method: 'GET',
        types: [GET_AMCAPPROVERTEMPLATE_SUCCESS, GET_AMCAPPROVERTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const amcAdminTemplate =
  () =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/excel-templates/amc_admin.xlsx',
        method: 'GET',
        types: [GET_AMCADMINTEMPLATE_SUCCESS, GET_AMCADMINTEMPLATE],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };
