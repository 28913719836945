import { AnyAction } from 'redux';
import { ApplicationProps } from '../types/api-types';
import {
  ADD_APPLICANT_SUCCESS,
  CREATE_APPLICATION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  SAVE_DISTRIBUTOR_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
} from '../types/application';

export type ApplicationStateProps = {
  application: ApplicationProps | null;
};

const initialState: ApplicationStateProps = {
  application: null,
};

function applicationReducer(
  state = initialState,
  action: AnyAction
): Partial<ApplicationStateProps> {
  switch (action.type) {
    case CREATE_APPLICATION_SUCCESS:
    case GET_APPLICATION_SUCCESS:
    case SAVE_DISTRIBUTOR_APPLICATION:
    case UPDATE_APPLICATION_SUCCESS:
    case ADD_APPLICANT_SUCCESS:
      return {
        ...state,
        application: action.body,
      };
    default:
      return state;
  }
}

export default applicationReducer;
