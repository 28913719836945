import { useState, useEffect } from 'react';
import { Box, Grid, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { AddTrusteeRequestBody, Trustee } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addTrustee,
  UpdateTrustee,
  getTrustesById,
  gettrusteeAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { TrusteeAndFundManagerSchema } from '../../utils/schema';
import { Location } from 'history';
// eslint-disable-next-line
import { CountryCodesDropDown } from '../commonComponents';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldEditable } from './AddressFields';
import { nonDigitRegex } from '../../utils/regex';

const initialValues: AddTrusteeRequestBody = {
  name: '',
  email: '',
  phone: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  panNumber: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  leegalityName: '',
  isActive: true,
};

export default function AddTrustee({
  location,
}: {
  location: Location<{ trusteeId: number }>;
}): JSX.Element {
  const { trusteeId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const [trusteeDetails, setTrusteeDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const { trusteeAccess } = (await dispatch(gettrusteeAccess())) as unknown as trusteeAccess;
        if (trusteeId) {
          const getTrusteeDetails = (await dispatch(
            getTrustesById(trusteeId)
          )) as unknown as Trustee;
          const {
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            isActive,
          } = getTrusteeDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getTrusteeDetails.user;
          setTrusteeDetails({
            ...trusteeDetails,
            name,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            phone,
            email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setAccessForEditAdd(trusteeAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddTrusteeRequestBody) => {
    try {
      if (!trusteeId) {
        await dispatch(
          addTrustee({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() })
        );
        history.push('trustee');
      } else {
        await dispatch(
          UpdateTrustee(trusteeId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('trustee-details', { trusteeId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={trusteeDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={TrusteeAndFundManagerSchema}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={trusteeId ? 'Trustee' : 'Onboarding Trustee'}
              onClickBack={() =>
                trusteeId
                  ? history.push('trustee-details', { trusteeId: values.id })
                  : history.push('trustee')
              }
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name (Individual) *"
                      placeholder="Enter Trustee Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="leegalityName"
                      label="Leegality Name *"
                      placeholder="Enter Leegality Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="Pan"
                      placeholder="Enter Pan "
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="email"
                      label="Email Id *"
                      placeholder="Enter Email Id"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <AddressFieldEditable accessForEditAdd={accessForEditAdd} />
                </Grid>
                {accessForEditAdd && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
