import { Redirect, Route, Switch, Router } from 'react-router-dom';
import { history } from '../store';
import Dashboard from '../components/dashboard/index';
import Investors from '../components/investors';
import OnboardingRouter from '../components/onboarding/Router';
import MobileLogin from '../components/auth/MobileLogin';
import LoginOtp from '../components/auth/loginOtp';
import RedirectRoute from './RedirectRoute';
import ProtectedRoute from './ProtectedRoute';
import ApplicationRoutes from '../components/investors/routes';
import Profile from '../components/profile/index';
import ApplicationDetails from '../components/ApplicationDetails/index';
import { useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../redux-store/reducers';
import InvestorRoutes from '../components/Investments/routes';
import UserManagementLayOut from '../components/userManagement/router';
import { CVLVerificationEnabled, ENABLE_RTA_APPROVER, USER_ROLES } from '../utils/constant';
import { FundsSetup } from '../components/FundsSetup';
import { RiskProfile } from '../components/RiskProfile';
import { AMCAdminsList } from '../components/AmcAdmins';
import ApplicationEsignList from '../components/ApplicationsEsignList';
import { CLEAR_GLOBAL_ERROR } from '../redux-store/types/auth';
import { useModal } from 'mui-modal-provider';
import SimpleDialog from '../components/commonComponents';
import NonIndividualApplicationRoutes, {
  NonIndividualApplicationEdit,
} from '../components/NonIndividualInvestor/routes';
import Reports from '../components/Reports/reports';
import { NonIndividualApplicationDetail } from '../components/NonIndividualApplicationDetails';
import ConsentEmailApproveReject, {
  ConsentEmailApplicationsList,
  ConsentEmailApprove,
  ConsentEmailReject,
} from '../components/ConsentEmailComponent';
import addBulkUsers from '../components/userManagement/addBulkUsers';
import addBulkFunds from '../components/FundsSetup/addFundBulk';
import adminSupport from '../components/adminSupport';
import LoginRequireRoute from './LoginRequireRoute';
import { NotAuthorized } from '../components/auth/NotAuthorized';
import Cvl from '../components/auth/Cvl';

function AppRoutes(): JSX.Element {
  const dispatch = useDispatch();
  const { toaster, message, severity } = useSelector((state: RootStateType) => state.toast);
  const { role = '' } = useSelector((state: RootStateType) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const errorState = useSelector((store: RootStateType) => store.error);
  useEffect(() => {
    toaster && enqueueSnackbar(message, { variant: severity as VariantType });
  }, [toaster]);

  useEffect(() => {
    if (errorState) {
      const { error = '', title = '' } = errorState;
      const { destroy } = showModal(SimpleDialog, {
        title,
        content: error,
        actions: [
          {
            label: 'OK',
            onClick: () => {
              destroy();
              dispatch({ type: CLEAR_GLOBAL_ERROR });
            },
          },
        ],
      });
    }
  }, [errorState]);

  const getBaseRoute = () => {
    if ([USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role)) {
      return '/applications';
    } else if (
      [
        USER_ROLES.TRUSTEE,
        USER_ROLES.FUND_MANAGER,
        USER_ROLES.POAAPPROVER,
        USER_ROLES.AMCAPPROVER,
        USER_ROLES.RTAAPPROVER,
      ].includes(role)
    ) {
      return '/applications-list';
    } else if ([USER_ROLES.AMC_ADMIN].includes(role)) {
      return '/dashboard';
    } else {
      return '/applications';
    }
  };

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          // eslint-disable-next-line
          render={(props: any) => (
            <Redirect {...props} to={getBaseRoute()} from={props.location.pathname} />
          )}
        />
        <RedirectRoute path="/login" component={MobileLogin} />
        <Route path="/not-authorized" component={NotAuthorized} />
        {CVLVerificationEnabled && <Route path="/cvl" component={Cvl} />}
        <RedirectRoute path="/verify-otp" component={LoginOtp} />
        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <ProtectedRoute
          path="/applications"
          component={Investors}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <ProtectedRoute
          path="/onboarding"
          component={OnboardingRouter}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
            USER_ROLES.AMCAPPROVER,
          ]}
        />
        <ProtectedRoute
          path="/application"
          component={ApplicationRoutes}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <ProtectedRoute
          path="/non-individual-application"
          component={NonIndividualApplicationRoutes}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <ProtectedRoute
          path="/profile"
          component={Profile}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <ProtectedRoute
          path="/application-details/:id"
          component={ApplicationDetails}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
            USER_ROLES.POAAPPROVER,
            USER_ROLES.AMCAPPROVER,
            ...(ENABLE_RTA_APPROVER ? [USER_ROLES.RTAAPPROVER] : []),
          ]}
        />
        <ProtectedRoute
          path="/funds-setup"
          component={FundsSetup}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <ProtectedRoute
          path="/risk-profile"
          component={RiskProfile}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <ProtectedRoute
          path="/amc-admins"
          component={AMCAdminsList}
          acceptedRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.DISTRIBUTOR]}
        />
        <ProtectedRoute
          path="/reports"
          component={Reports}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <ProtectedRoute
          path="/applications-list"
          component={ApplicationEsignList}
          acceptedRoles={[
            USER_ROLES.TRUSTEE,
            USER_ROLES.FUND_MANAGER,
            USER_ROLES.POAAPPROVER,
            USER_ROLES.AMCAPPROVER,
            ...(ENABLE_RTA_APPROVER ? [USER_ROLES.RTAAPPROVER] : []),
          ]}
        />
        <ProtectedRoute
          path="/user-management"
          component={UserManagementLayOut}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <ProtectedRoute
          path="/create-bulk-user"
          component={addBulkUsers}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <ProtectedRoute
          path="/create-bulk-funds"
          component={addBulkFunds}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <ProtectedRoute
          path="/non-individual-application-details/:id"
          component={NonIndividualApplicationDetail}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
            USER_ROLES.AMCAPPROVER,
          ]}
        />
        <ProtectedRoute
          path="/non-individual-application-edit"
          component={NonIndividualApplicationEdit}
          acceptedRoles={[USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER]}
        />
        <ProtectedRoute
          path="/application-preview/:id"
          component={ApplicationDetails}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <ProtectedRoute
          path="/non-individual-application-preview/:id"
          component={NonIndividualApplicationDetail}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
          ]}
        />
        <Route path="/investment-details" component={InvestorRoutes} />
        <Route path="/investor-consent/:verificationToken" component={ConsentEmailApproveReject} />
        <Route path="/investor-consent-approved" component={ConsentEmailApprove} />
        <Route path="/investor-consent-reject" component={ConsentEmailReject} />
        <ProtectedRoute
          path="/view-consent-email-list"
          component={ConsentEmailApplicationsList}
          acceptedRoles={[
            USER_ROLES.AMC_ADMIN,
            USER_ROLES.RM,
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.SUBDISTRIBUTOR,
            USER_ROLES.AMCAPPROVER,
          ]}
        />
        <ProtectedRoute
          path="/admin-support"
          component={adminSupport}
          acceptedRoles={[USER_ROLES.AMC_ADMIN]}
        />
        <LoginRequireRoute path="*" />
        {/* <Route
          path="*"
          render={() => {
            return 'Page you are requesting is not found';
          }}
        /> */}
      </Switch>
    </Router>
  );
}

export default AppRoutes;
