import { Box, Typography, Switch, Stack } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AifApproverAccess,
  AmcApproverAccess,
  getAIFApproverList,
  getAmcApproverAccess,
  getAMCApproverList,
  getRtaApproverAccess,
  UpdateAifApprover,
  UpdateAmcApprover,
} from '../../redux-store/actions/userManagement';
import { AddAifApproverRequestBody, RTAApprover } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { UserListHeader } from './userBackButton';
import { styles } from '../../Styles/styles';
import { ENABLE_RTA_APPROVER } from '../../utils/constant';
import { SearchUser } from './SearchUser';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: RTAApprover) => {
      return (
        <Typography
          variant="h5"
          sx={styles.UsersClickbleColumn}
          component={RouterLink}
          to={{ pathname: 'aif-approver-details', state: { aifApproverId: row.id } }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: RTAApprover) => row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: RTAApprover) => row.user.email || 'N/A',
  },
];

function aifApprover(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [aifApproverList, setAifApproverList] = useState<RTAApprover[]>([]);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    aifApproverRowData: AddAifApproverRequestBody;
  } | null>(null);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (ENABLE_RTA_APPROVER) {
          const res = (await dispatch(getAIFApproverList())) as unknown as RTAApprover[];
          const { rtaApproverAccess } = (await dispatch(
            getRtaApproverAccess()
          )) as unknown as AifApproverAccess;
          if (!isComponentAlive) return;
          setAifApproverList(res);
          setAccessForEditAdd(rtaApproverAccess);
        } else {
          const res = (await dispatch(getAMCApproverList())) as unknown as RTAApprover[];
          const { amcApproverAccess } = (await dispatch(
            getAmcApproverAccess()
          )) as unknown as AmcApproverAccess;
          if (!isComponentAlive) return;
          setAifApproverList(res);
          setAccessForEditAdd(amcApproverAccess);
        }
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessViewOrEditCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddAifApproverRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ aifApproverRowData: row })}
                disabled={!accessForEditAdd}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: AifApprover): JSX.Element => {
    //     return (
    //       <>
    //         {accessForEditAdd && row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{ pathname: 'edit-aifapprover', state: { aifApproverId: row.id } }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-aifapprover', state: { aifApproverId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <SearchUser search={search} setSearch={setSearch} placeholder={'Search AIF Approver'} />

        <UserListHeader
          addUser="Add AIF Approver"
          listName="List of AIF Approvers"
          accessForEditAdd={accessForEditAdd}
          onClickAdd={() => history.push('add-aif-approver')}
        />
        {useMemo(
          () => (
            <DataTable
              tableData={aifApproverList.filter(
                (item) =>
                  item.user?.firstName?.toLowerCase()?.includes(search.toLowerCase()) ||
                  item.user?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
                  item.user?.phone?.toLowerCase()?.includes(search.toLowerCase())
              )}
              tableHeader={[...columns, ...AccessViewOrEditCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
              tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
            />
          ),
          [aifApproverList, currentPage, isLoading, search]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.aifApproverRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              let updatedResponse: RTAApprover;
              const _values = {
                Id: openDialog.aifApproverRowData.id as number,
                body: {
                  ...openDialog.aifApproverRowData,
                  isActive: !openDialog?.aifApproverRowData.isActive,
                },
              };
              if (ENABLE_RTA_APPROVER) {
                updatedResponse = (await dispatch(
                  UpdateAifApprover(_values.Id, _values.body)
                )) as unknown as RTAApprover;
              } else {
                updatedResponse = (await dispatch(
                  UpdateAmcApprover(_values.Id, _values.body)
                )) as unknown as RTAApprover;
              }

              setAifApproverList(
                aifApproverList?.map((aifApprover) => {
                  if (Number(aifApprover.id) === Number(updatedResponse.id)) {
                    return {
                      ...aifApprover,
                      ...updatedResponse,
                    };
                  }
                  return aifApprover;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default aifApprover;
