import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
// eslint-disable-next-line
import { formatShortDate } from '../../utils/date';
import { currencyConversion, isFieldApplicable } from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';
import { YES_NO_BOOLEAN_MASTER } from '../../utils/constant';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Accredited Investor'}
                  description={
                    YES_NO_BOOLEAN_MASTER[application.isAccreditedInvestor?.toString() || '']
                  }
                />
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue title={'Class Plan'} description={application.plan?.planDescription} />
                <KeyValue
                  title={'Commitment Amount'}
                  description={application.commitmentAmount}
                  amount={currencyConversion(
                    application.commitmentAmount,
                    application.plan?.currency
                  )}
                />
                {isFieldApplicable(application?.managementFeeApplicable || '') && (
                  <KeyValue
                    title={'Management Fees Rate'}
                    description={application?.managementFeesRate}
                  />
                )}
                {isFieldApplicable(application?.placementFeeApplicable || '') && (
                  <KeyValue title={'Placement Fees'} description={application?.placementFee} />
                )}
                {isFieldApplicable(application?.operatingExpenseApplicable || '') && (
                  <KeyValue
                    title={'Operating Expense'}
                    description={application?.operatingExpenses}
                  />
                )}
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                <KeyValue
                  title={'Contribution Amount'}
                  description={application.contributionChequeAmount}
                  amount={currencyConversion(
                    application.contributionChequeAmount,
                    application.plan?.currency
                  )}
                />
                {isFieldApplicable(application?.setupFeeApplicable || '') && (
                  <>
                    <KeyValue
                      title={'Setup Fee Percentage'}
                      description={application.setupFeePercentage}
                    />
                    <KeyValue
                      title={'Setup Fee Amount'}
                      description={application.setupFeeAmount}
                      amount={currencyConversion(
                        application.setupFeeAmount,
                        application.plan?.currency
                      )}
                    />
                    <KeyValue
                      title={'GST (GST On Setup Fee Amount)'}
                      description={application.setupFeeGST}
                      amount={currencyConversion(
                        application.setupFeeGST,
                        application.plan?.currency
                      )}
                    />
                    <KeyValue
                      title={'Total Setup Fee'}
                      description={application.totalSetupFee}
                      amount={currencyConversion(
                        application.totalSetupFee,
                        application.plan?.currency
                      )}
                    />
                    <KeyValue
                      title={'TDS'}
                      description={application.setupFeeTDS}
                      amount={currencyConversion(
                        application.setupFeeTDS,
                        application.plan?.currency
                      )}
                    />
                    <KeyValue
                      title={'Setup Fee Payable'}
                      description={application.netSetupFeeLessTDS}
                      amount={currencyConversion(
                        application.netSetupFeeLessTDS,
                        application.plan?.currency
                      )}
                    />
                  </>
                )}

                {isFieldApplicable(application?.hurdleRateApplicable || '') && (
                  <KeyValue title={'Hurdle Rate'} description={application?.hurdleRate} />
                )}
                {isFieldApplicable(application?.performanceFeeApplicable || '') && (
                  <KeyValue title={'Performance Fee'} description={application?.performanceFee} />
                )}
                {isFieldApplicable(application?.exitChargeApplicable || '') && (
                  <KeyValue title={'Exit Charge'} description={application?.exitCharge} />
                )}
                <KeyValue
                  title={'Total Amount Payable'}
                  description={application.totalAmountPayable}
                  amount={currencyConversion(
                    application.totalAmountPayable,
                    application.plan?.currency
                  )}
                />
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
}
