import { CommonLayout } from '../commonComponents';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch, Router } from 'react-router';
import { getApplicationDetails } from '../../redux-store/actions/application';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Location } from 'history';
import { RootStateType } from '../../redux-store/reducers';
import { RiskProfileEnabled, USER_ROLES } from '../../utils/constant';
import { InvestorDetailsLayout } from '../investors/components';
import ContactDetails from './ContactDetails';
import BankDetails from '../investors/bankDetails';
import InvestmentPaymentDetails from '../investors/investmentPaymentDetails';
import DocumentDetails from './DocumentDetails';
import ContributorDetails from './ContributorDetails';
import AuthorisedSignatories from './Authorised';
import Ubo from './ubo';
import { Fatca } from './fatca';
import { Footer } from '../commonComponents';
import { AppBar, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import DistributorDetails from '../investors/distributorDetails';
import { ExitToApp } from '@mui/icons-material';
import { logout } from '../../redux-store/actions/auth';
import riskProfile from '../investors/riskProfileDetails';
import { LogoEditApplication } from '../Logo';

export const Routes = ({
  id,
  applicant1ReferenceId,
}: {
  id: string;
  applicant1ReferenceId?: string;
  applicationType?: string;
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const {
    auth: { role },
    application: { application: _application },
    investor: { token = '' },
  } = useSelector((store: RootStateType) => store);

  const { applicationNumber } = _application || {};

  useEffect(() => {
    async function fetchApplication() {
      try {
        if (role === USER_ROLES.INVESTOR && !token) {
          history.push(`/investment-details/${applicant1ReferenceId}/details`);
          return;
        }
        if (id) {
          (await dispatch(getApplicationDetails(id))) as unknown as ApplicationProps;
        } else {
          history.push('/');
          console.error('No application id found');
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    }
    fetchApplication();
  }, []);

  return (
    <InvestorDetailsLayout
      applicationId={id}
      applicant1ReferenceId={applicant1ReferenceId}
      applicationNumber={applicationNumber || ''}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: `${path}/distributor-details`,
                  state: { id, applicant1ReferenceId },
                }}
                from={location.pathname}
              />
            )}
          />
          <Route path={`${path}/distributor-details`} component={DistributorDetails} />
          <Route path={`${path}/contributor-details`} component={ContributorDetails} />
          {RiskProfileEnabled && <Route path={`${path}/risk-profiles`} component={riskProfile} />}
          <Route path={`${path}/contact-details`} component={ContactDetails} />
          <Route path={`${path}/investment-payment-details`} component={InvestmentPaymentDetails} />
          <Route path={`${path}/bank-details`} component={BankDetails} />
          <Route path={`${path}/document-details`} component={DocumentDetails} />
          <Route path={`${path}/authorised-signatory`} component={AuthorisedSignatories} />
          <Route path={`${path}/declaration-ubo`} component={Ubo} />
          <Route path={`${path}/fatca`} component={Fatca} />
        </Switch>
      </Router>
    </InvestorDetailsLayout>
  );
};

//For Amc Approver
export const NonIndividualApplicationEdit = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: '48px',
            paddingLeft: '48px',
          }}>
          <LogoEditApplication />
          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={() => {
                dispatch(logout());
                history.push('/login');
              }}>
              <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
        <Routes id={id} applicant1ReferenceId={applicant1ReferenceId} />
      </Box>
      <Footer />
    </Box>
  );
};

const NonIndividualApplicationRoutes = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string; applicationType?: string }>;
}): JSX.Element => {
  const { id = '', applicant1ReferenceId = '', applicationType = '' } = location.state || {};
  return (
    <CommonLayout>
      <Routes
        id={id}
        applicant1ReferenceId={applicant1ReferenceId}
        applicationType={applicationType}
      />
    </CommonLayout>
  );
};

export default NonIndividualApplicationRoutes;
