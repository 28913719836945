import { Box, Typography, Stack, Switch } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AmcAdminAccess,
  getAmcAdminAccess,
  getAmcAdminList,
  updateAmcAdmin,
} from '../../redux-store/actions/userManagement';
import { AddAmcAdminRequestBody, AmcAdmin } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { AccessHeader } from './relationShipManagers';
import { ConfirmationDialog } from '../commonComponents';
import { styles } from '../../Styles/styles';
import { UserListHeader } from './userBackButton';
import { SearchUser } from './SearchUser';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: AmcAdmin) => {
      return (
        <Typography
          variant="h5"
          sx={styles.UsersClickbleColumn}
          component={RouterLink}
          to={{ pathname: 'amc-admin-details', state: { amcAdminId: row.id } }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: AmcAdmin) => row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: AmcAdmin) => row.user.email || 'N/A',
  },
];

function amcAdmin(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [amcAdminList, setAmcAdminList] = useState<AmcAdmin[]>([]);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);
  const [openDialog, setDialog] = useState<{
    amcAdminRowData: AddAmcAdminRequestBody;
  } | null>(null);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getAmcAdminList())) as unknown as AmcAdmin[];
        const { amcAdminAccess } = (await dispatch(
          getAmcAdminAccess()
        )) as unknown as AmcAdminAccess;
        if (!isComponentAlive) return;
        setAmcAdminList(res);
        setAccessForEditAdd(amcAdminAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddAmcAdminRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ amcAdminRowData: row })}
                disabled={!accessForEditAdd}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <SearchUser search={search} setSearch={setSearch} placeholder={'Search Admin'} />
        <UserListHeader
          addUser="Add AMC Admin"
          listName="List of AMC Admins"
          accessForEditAdd={accessForEditAdd}
          onClickAdd={() => history.push('add-amc-admin')}
        />
        {useMemo(
          () => (
            <DataTable
              tableData={amcAdminList.filter(
                (item) =>
                  item.user?.firstName?.toLowerCase()?.includes(search.toLowerCase()) ||
                  item.user?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
                  item.user?.phone?.toLowerCase()?.includes(search.toLowerCase())
              )}
              tableHeader={[...columns, ...AccessCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
              tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
            />
          ),
          [amcAdminList, currentPage, isLoading, search]
        )}
        <ConfirmationDialog
          message={`Are you sure you want to ${
            openDialog?.amcAdminRowData.isActive ? 'disable' : 'enable'
          } the access to this user ?`}
          open={openDialog !== null}
          setOpen={() => setDialog(null)}
          onSave={async () => {
            setDialog(null);
            try {
              if (openDialog) {
                const updatedResponse = (await dispatch(
                  updateAmcAdmin(openDialog.amcAdminRowData.id as number, {
                    ...openDialog.amcAdminRowData,
                    isActive: !openDialog?.amcAdminRowData.isActive,
                  })
                )) as unknown as AmcAdmin;
                setAmcAdminList(
                  amcAdminList?.map((amcAdmin) => {
                    if (Number(amcAdmin.id) === Number(updatedResponse.id)) {
                      return {
                        ...amcAdmin,
                        ...updatedResponse,
                      };
                    }
                    return amcAdmin;
                  })
                );
              }
            } catch (e) {
              console.error((e as Error).message);
            }
          }}
          onCancel={() => setDialog(null)}
        />
      </Box>
    </>
  );
}

export default amcAdmin;
