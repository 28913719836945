/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { encodeBase64 } from '../../utils/utilityFunctions';
import { CALL_API } from '../middleware';
import {
  AcknowledgeAmlCheckRequestBody,
  AcknowledgeAmlCheckResponseBody,
  ApplicationProps,
  ConsentEmailApproveRejectRequestBody,
  ConsentEmailApproveRejectResponseBody,
  EnablePOARequestBody,
  EnablePOAResponseBody,
  esignApplicationResponse,
  GetDetailsFromRefIDResponse,
  GetOtpForInvestorRequestBody,
  GetOtpForInvestorResponse,
  investmentDetailsAplicationRef,
  IsApplicableForPOAResponseBody,
  SendConsentEmailRequestBody,
  SendConsentEmailResponseBody,
  SendEmailRequestBody,
  SendOtpRequestBody,
  SendOtpResponseBody,
  verifyInvestorResponse,
  VerifyOtpRequestBody,
  VerifyOtpResponseBody,
  VerifyPanRequestBody,
  VerifyPanResponseBody,
} from '../types/api-types';
import { VERIFY_OTP } from '../types/auth';
import {
  SEND_OTP,
  SEND_OTP_SUCCESS,
  VERIFY_PAN,
  VERIFY_PAN_SUCCESS,
  VERIF_OTP_SUCCESS,
  GET_DETAILS_SUCCESS,
  GET_DETAILS,
  GET_OTP_SUCCESS,
  GET_OTP,
  VERIFY_OTP_INVESTOR_SUCCESS,
  VERIFY_OTP_INVESTOR,
  GET_APPLICATION_WITH_REF_ID_SUCCESS,
  GET_APPLICATION_WITH_REF_ID,
  ESIGN_DOCUMENT_SUCCESS,
  ESIGN_DOCUMENT,
  GET_Investment_Details_Aplication_Ref_SUCCESS,
  GET_Investment_Details_Aplication_Ref,
  ENABLE_POA,
  ENABLE_POA_SUCCESS,
  APPLICABLE_FOR_POA_SUCCESS,
  APPLICABLE_FOR_POA,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL,
  ACKNOWLEDGE_AML_CHECK_SUCCESS,
  ACKNOWLEDGE_AML_CHECK,
  SEND_CONSENTEMAIL_SUCCESS,
  SEND_CONSENTEMAIL,
  SEND_CONSENTEMAIL_APPROVE_SUCCESS,
  SEND_CONSENTEMAIL_APPROVE,
  SEND_CONSENTEMAIL_REJECT_SUCCESS,
  SEND_CONSENTEMAIL_REJECT,
  GET_APPLICATION_DOWNLOAD_SUCCESS,
  GET_APPLICATION_DOWNLOAD,
} from '../types/onBoarding';

export const verifyPan =
  (body: VerifyPanRequestBody) =>
  async (dispatch: any): Promise<VerifyPanResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/verify-pan`,
        method: 'POST',
        types: [VERIFY_PAN_SUCCESS, VERIFY_PAN],
        body: {
          payload: encodeBase64(
            JSON.stringify({
              pan: body.pan.toUpperCase(),
              dob: body.dob,
              onboardingType: body.onboardingType,
            })
          ),
        },
      },
    });
  };

export const sendOtp =
  (body: SendOtpRequestBody) =>
  async (dispatch: any): Promise<SendOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/send-otp`,
        method: 'POST',
        types: [SEND_OTP_SUCCESS, SEND_OTP],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const verifyOtp =
  (body: VerifyOtpRequestBody) =>
  async (dispatch: any): Promise<VerifyOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/verify-otp`,
        method: 'POST',
        types: [VERIF_OTP_SUCCESS, VERIFY_OTP],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const getDetailsWithReferenceId =
  (referenceId: string) =>
  async (dispatch: any): Promise<GetDetailsFromRefIDResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/details/${referenceId}`,
        method: 'GET',
        types: [GET_DETAILS_SUCCESS, GET_DETAILS],
      },
    });
  };

export const getOtpForInvestor =
  (referenceId: string) =>
  async (dispatch: any): Promise<GetOtpForInvestorResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/get-otp/${referenceId}`,
        method: 'GET',
        types: [GET_OTP_SUCCESS, GET_OTP],
      },
    });
  };

export const verifyOtpForInvestor =
  (referenceId: string, body: GetOtpForInvestorRequestBody) =>
  async (dispatch: any): Promise<verifyInvestorResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/verify-otp/${referenceId}`,
        method: 'POST',
        types: [VERIFY_OTP_INVESTOR_SUCCESS, VERIFY_OTP_INVESTOR],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const getApplicationDetailsWithRefId =
  (referenceId: string) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/application-details/${referenceId}`,
        method: 'GET',
        types: [GET_APPLICATION_WITH_REF_ID_SUCCESS, GET_APPLICATION_WITH_REF_ID],
      },
    });
  };

export const esignApplication =
  (referenceId: string) =>
  async (dispatch: any): Promise<esignApplicationResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/sign/${referenceId}`,
        method: 'POST',
        types: [ESIGN_DOCUMENT_SUCCESS, ESIGN_DOCUMENT],
      },
    });
  };

export const ApplicationRefs =
  (applicationId: string | number) =>
  async (dispatch: any): Promise<investmentDetailsAplicationRef> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/get-referenceids/${applicationId}`,
        method: 'GET',
        types: [
          GET_Investment_Details_Aplication_Ref_SUCCESS,
          GET_Investment_Details_Aplication_Ref,
        ],
      },
    });
  };

export const EnablePOA =
  (body: EnablePOARequestBody) =>
  async (dispatch: any): Promise<EnablePOAResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/enable-poa`,
        method: 'POST',
        types: [ENABLE_POA_SUCCESS, ENABLE_POA],
        body,
      },
    });
  };

export const IsApplicableForPOA =
  () =>
  async (dispatch: any): Promise<IsApplicableForPOAResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/applicable-for-power-of-attorney`,
        method: 'GET',
        types: [APPLICABLE_FOR_POA_SUCCESS, APPLICABLE_FOR_POA],
      },
    });
  };

export const SendEmail =
  (body: SendEmailRequestBody) =>
  async (dispatch: any): Promise<VerifyOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/get-non-individual-application-token`,
        method: 'POST',
        types: [SEND_EMAIL_SUCCESS, SEND_EMAIL],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const AcknowledgeAmlCheck =
  (body: AcknowledgeAmlCheckRequestBody) =>
  async (dispatch: any): Promise<AcknowledgeAmlCheckResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/acknowledge-aml-check`,
        method: 'POST',
        types: [ACKNOWLEDGE_AML_CHECK_SUCCESS, ACKNOWLEDGE_AML_CHECK],
        body,
      },
    });
  };

export const sendConsentEmail =
  (body: SendConsentEmailRequestBody) =>
  async (dispatch: any): Promise<SendConsentEmailResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/send-consent-email`,
        method: 'POST',
        types: [SEND_CONSENTEMAIL_SUCCESS, SEND_CONSENTEMAIL],
        body,
      },
    });
  };

export const approveConsentEmail =
  (body: ConsentEmailApproveRejectRequestBody) =>
  async (dispatch: any): Promise<ConsentEmailApproveRejectResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding-consent/approve`,
        method: 'POST',
        types: [SEND_CONSENTEMAIL_APPROVE_SUCCESS, SEND_CONSENTEMAIL_APPROVE],
        body,
      },
    });
  };

export const rejectConsentEmail =
  (body: ConsentEmailApproveRejectRequestBody) =>
  async (dispatch: any): Promise<ConsentEmailApproveRejectResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding-consent/reject`,
        method: 'POST',
        types: [SEND_CONSENTEMAIL_REJECT_SUCCESS, SEND_CONSENTEMAIL_REJECT],
        body,
      },
    });
  };

export const ApplicationDownload =
  (applicationId: string) =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: `/pdf/onboarding-application/pdf/${applicationId}`,
        method: 'GET',
        types: [GET_APPLICATION_DOWNLOAD_SUCCESS, GET_APPLICATION_DOWNLOAD],
        contentType: 'application/pdf',
      },
    });
  };
