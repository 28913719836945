import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { RmsType } from '../../redux-store/types/api-types';
import { getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldReadonly } from './AddressFields';
import { RoleTypes } from '../../utils/constant';

export default function RMDetails({
  location,
}: {
  location: Location<{ rmId: number }>;
}): JSX.Element {
  const { rmId: id } = location.state || { rmId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [rmDetails, setRMDetails] = useState<RmsType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getRmDetails = (await dispatch(getRMById(Number(id)))) as unknown as RmsType;
          setRMDetails(getRmDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="RM Details"
          accessForEditAdd={true}
          onClickBack={() => history.push('relationship-managers')}
          onClickEdit={() => history.push('edit-rm-details', { rmId: rmDetails?.id })}
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          rmDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'RM Name'} description={rmDetails.name} />
                    <KeyValue title={'Email Id'} description={rmDetails.user.email} />
                    <KeyValue title={'Mobile Number'} description={rmDetails.user.phone} />
                    <KeyValue title={'Role Type'} description={RoleTypes[rmDetails.roleType]} />
                    <KeyValue title={'Pan'} description={rmDetails.panNumber} />
                    <KeyValue title={'Emp Code'} description={rmDetails.empCode} />
                    <AddressFieldReadonly AddressField={rmDetails} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
